<template>
  <div>
    <el-button class="search" size="large" @click="handleSearch = true"
        >Search</el-button
      >
      <!-- モーダル開始 -->
      <el-dialog
          v-model="handleSearch"
          width="70%"
          title=""
          destroy-on-close
          center
        >

      <el-form
        ref="Form"
        :model="formInline"
        label-width="150px"
        class="modalform"
      >

      <!-- 社員番号(範囲指定？) -->
      <el-form class="form">
        <el-form-item class="form" id="employeeNumber" label="社員番号">
          <el-col :span="5">
            <el-input v-model="formInline.employeeNumberStart"></el-input>
          </el-col>
          <el-col :span="2" class="text-center">
            <span class="text-gray-500"> ～ </span>
          </el-col>
          <el-col :span="5">
            <el-input v-model="formInline.employeeNumberEnd"></el-input>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- 氏名 -->
      <el-form class="form">
        <el-form-item class="form" id="name" label="氏名">
          <el-input v-model="formInline.name"></el-input>
        </el-form-item>
      </el-form>

      <!-- 氏名フリガナ -->
      <el-form class="form">
        <el-form-item class="form" id="nameFurikana" label="氏名フリガナ">
          <el-input v-model="formInline.nameFurikana"></el-input>
        </el-form-item>
      </el-form>

      <!-- 氏名英字 -->
      <el-form class="form">
        <el-form-item class="form" id="nameEiji" label="氏名英字">
          <el-input v-model="formInline.nameEiji"></el-input>
        </el-form-item>
      </el-form>

      <!-- 法定氏名 -->
      <el-form class="form">
        <el-form-item class="form" id="legalName" label="法定氏名">
          <el-input v-model="formInline.legalName"></el-input>
        </el-form-item>
      </el-form>

      <!-- 法定氏名カナ -->
      <el-form class="form">
        <el-form-item class="form" id="legalNameKana" label="法定氏名カナ">
          <el-input v-model="formInline.legalNameKana"></el-input>
        </el-form-item>
      </el-form>

      <!-- 旧姓正式漢字 -->
      <el-form class="form">
        <el-form-item class="form" id="officialMaidenNameKanji" label="旧姓正式漢字">
          <el-input v-model="formInline.officialMaidenNameKanji"></el-input>
        </el-form-item>
      </el-form>

      <!-- 旧姓フリガナ -->
      <el-form class="form">
        <el-form-item class="form" id="MaidenNameFurikana" label="旧姓フリガナ">
          <el-input v-model="formInline.MaidenNameFurikana"></el-input>
        </el-form-item>
      </el-form>

      <!-- 旧姓英字 -->
      <el-form class="form">
        <el-form-item class="form" id="MaidenNameEiji" label="旧姓英字">
          <el-input v-model="formInline.MaidenNameEiji"></el-input>
        </el-form-item>
      </el-form>

      <!-- 性別 -->
      <el-form class="form">
      <el-form-item class="form" id="gender" label="性別" prop="gender">
        <el-select v-model="formInline.gender" placeholder="性別">
          <el-option label="男" value="男"></el-option>
          <el-option label="女" value="女"></el-option>
          <el-option label="その他" value="その他"></el-option>
        </el-select>
      </el-form-item>
      </el-form>

      <!-- 性別名称 -->
      <el-form class="form">
        <el-form-item class="form" id="genderName" label="性別名称">
          <el-input v-model="formInline.genderName"></el-input>
        </el-form-item>
      </el-form>

      <!-- 生年月日 -->
      <el-form class="form">
        <el-form-item class="form" id="dateOfBirth" label="生年月日">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.dateOfBirth"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- 入社日付 -->
      <el-form class="form">
        <el-form-item class="form" id="dateOfEmployment" label="入社日付">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.dateOfEmployment"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- 最終出社日変更日 -->
      <el-form class="form">
        <el-form-item class="form" id="lastWorkingChangeDate" label="最終出社日変更日">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.lastWorkingChangeDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- 退職日付 -->
      <el-form class="form">
        <el-form-item class="form" id="retirementDate" label="退職日付">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.okinawaDeformedLaborFlagChangeDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <!-- 勤続年月 -->
      <el-form class="form">
      <el-form-item class="form" id="yearsAndMarchOfService" label="勤続年月" prop="yearsAndMarchOfService">
        <el-select v-model="formInline.yearsAndMarchOfService" placeholder="勤続年月">
          <el-option label="男" value="男"></el-option>
          <el-option label="女" value="女"></el-option>
          <el-option label="その他" value="その他"></el-option>
        </el-select>
      </el-form-item>
      </el-form>

      <!-- 勤続年数 -->
      <el-form class="form">
      <el-form-item class="form" id="yearsOfService" label="勤続年月" prop="yearsOfService">
        <el-select v-model="formInline.yearsOfService" placeholder="勤続年月">
          <el-option label="1年" value="1年"></el-option>
          <el-option label="2年" value="2年"></el-option>
          <el-option label="3年" value="3年"></el-option>
        </el-select>
      </el-form-item>
      </el-form>


      <!-- アカウントID -->
      <el-form class="form">
        <el-form-item class="form" id="id" label="アカウントID">
          <el-input v-model="formInline.id"></el-input>
        </el-form-item>
      </el-form>

      <!-- 会社Eメールアドレス -->
      <el-form class="form">
        <el-form-item class="form" id="companyEmailAddress1" label="会社Eメールアドレス">
          <el-input v-model="formInline.companyEmailAddress1"></el-input>
        </el-form-item>
      </el-form>
      <!-- 会社Eメールアドレス２ -->
      <el-form class="form">
        <el-form-item class="form" id="companyEmailAddress2" label="会社Eメールアドレス２">
          <el-input v-model="formInline.companyEmailAddress2"></el-input>
        </el-form-item>
      </el-form>

      <!-- 内線番号 -->
      <el-form class="form">
        <el-form-item class="form" id="extensionNumber1" label="内線番号">
          <el-input v-model="formInline.extensionNumber1"></el-input>
        </el-form-item>
      </el-form>

      <!-- ダイヤルイン番号 -->
      <el-form class="form">
        <el-form-item class="form" id="DialinNumber1" label="ダイヤルイン番号">
          <el-input v-model="formInline.DialinNumber1"></el-input>
        </el-form-item>
      </el-form>
        
      <!-- POPS利用区分 -->
      <el-form class="form">
        <el-form-item class="form" id="POPS" label="POPS利用区分">
          <el-input v-model="formInline.POPS"></el-input>
        </el-form-item>
      </el-form>

      <!-- 会社Eメールアドレス３ -->
      <el-form class="form">
        <el-form-item class="form" id="companyEmailAddress3" label="会社Eメールアドレス３">
          <el-input v-model="formInline.companyEmailAddress3"></el-input>
        </el-form-item>
      </el-form>

      <!-- 内線番号第２ -->
      <el-form class="form">
        <el-form-item class="form" id="extensionNumber2" label="内線番号第２">
          <el-input v-model="formInline.extensionNumber2"></el-input>
        </el-form-item>
      </el-form>

      <!-- 内線番号第３ -->
      <el-form class="form">
        <el-form-item class="form" id="extensionNumber3" label="内線番号第３">
          <el-input v-model="formInline.extensionNumber3"></el-input>
        </el-form-item>
      </el-form>

      <!-- 会社携帯番号 -->
      <el-form class="form">
        <el-form-item class="form" id="companyMobileNumber1" label="会社携帯番号">
          <el-input v-model="formInline.companyMobileNumber1"></el-input>
        </el-form-item>
      </el-form>

      <!-- 会社携帯番号２ -->
      <el-form class="form">
        <el-form-item class="form" id="companyMobileNumber2" label="会社携帯番号２">
          <el-input v-model="formInline.companyMobileNumber2"></el-input>
        </el-form-item>
      </el-form>

      <!-- 会社携帯番号３ -->
      <el-form class="form">
        <el-form-item class="form" id="companyMobileNumber3" label="会社携帯番号３">
          <el-input v-model="formInline.companyMobileNumber3"></el-input>
        </el-form-item>
      </el-form>

      <!-- ダイヤルイン番号第２ -->
      <el-form class="form">
        <el-form-item class="form" id="DialinNumber2" label="ダイヤルイン番号第２">
          <el-input v-model="formInline.DialinNumber2"></el-input>
        </el-form-item>
      </el-form>

      <!-- ダイヤルイン番号第３ -->
      <el-form class="form">
        <el-form-item class="form" id="DialinNumber3" label="ダイヤルイン番号第３">
          <el-input v-model="formInline.DialinNumber3"></el-input>
        </el-form-item>
      </el-form>

      <!-- 戸籍氏名 -->
      <el-form class="form">
        <el-form-item class="form" id="familyRegisterName" label="戸籍氏名">
          <el-input v-model="formInline.familyRegisterName"></el-input>
        </el-form-item>
      </el-form>

      <!-- 退職区分 -->
      <el-form class="form">
        <el-form-item class="form" id="retirementDivision" label="退職区分">
          <el-input v-model="formInline.retirementDivision"></el-input>
        </el-form-item>
      </el-form>

      <!-- 退職区分名称 -->
      <el-form class="form">
        <el-form-item class="form" id="retirementDivisionName" label="退職区分名称">
          <el-input v-model="formInline.retirementDivisionName"></el-input>
        </el-form-item>
      </el-form>

      <!-- 最終出社日 -->
      <el-form-item class="form" id="lastWorkingDate" label="最終出社日">
        <el-col :span="11">
          <el-date-picker
            v-model="formInline.lastWorkingDate"
            type="date"
            placeholder="Pick a date"
            style="width: 200%"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 登録日時-->
        <el-form-item class="form" id="registrationDate" label="登録日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.registrationDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 登録者-->
        <el-form-item class="form" id="registrants" label="登録者">
          <el-input v-model="formInline.registrants"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 更新日時-->
        <el-form-item class="form" id="updateDate" label="更新日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.updateDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 更新者-->
        <el-form-item class="form" id="Updater" label="更新者">
          <el-input v-model="formInline.Updater"></el-input>
        </el-form-item>
      </el-form>

</el-form>
      <!-- ボタン -->
      <div class="button">
        <el-button @click="handleSearch = false">Cancel</el-button>
        <el-button type="primary" @click="submitForm('formInline')"
          >Submit</el-button
        > 
      </div>
    </el-dialog>
    <!-- モーダル終了 -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
// import axios from "axios";

export default defineComponent({
  name: "SearchModal",
  props: ["clums"],
  setup() {
    const num = ref(1)
    const handleChange = (value) => {
      console.log(value)
    }
    const state = reactive({
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
          
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
    })

    return {
      ...toRefs(state),
      handleSearch: ref(false),
      num,
      handleChange,
    }
  },

  data() {
    return {
      formInline: {
          employeeNumber: '',
          name: '',
          nameFurikana: '',
          nameEiji: '',
          legalName: '',
          legalNameKana: '',
          officialMaidenNameKanji: '',
          MaidenNameFurikana: '',
          MaidenNameEiji: '',
          gender: '',
          genderName: '',
          dateOfBirth: '',
          dateOfEmployment: '',
          lastWorkingChangeDate: '',
          retirementDate: '',
          yearsAndMarchOfService: '',
          yearsOfService: '',
          id: '',
          companyEmailAddress1: '',
          companyEmailAddress2: '',
          extensionNumber1: '',
          DialinNumber1: '',
          POPS: '',
          companyEmailAddress3: '',
          extensionNumber2: '',
          extensionNumber3: '',
          companyMobileNumber1: '',
          companyMobileNumber2: '',
          companyMobileNumber3: '',
          DialinNumber2: '',
          DialinNumber3: '',
          familyRegisterName: '',
          retirementDivision: '',
          retirementDivisionName: '',
          lastWorkingDate: '',
          registrationDate: '',
          registrants: '',
          updateDate: '',
          Updater: '',
      },
    }
  },

  created() {
  },

  methods: {
    submitForm() {
      }
    },

    // Search product
    async saveProduct() {
      try {
        // 検索の処理

      } catch (err) {
        console.log(err);
      }
    }
});
</script>

<style>
div > .search {
  margin: 10px;
  margin-top: 20px;
  float: right;
  background-color:rgb(111, 230, 230);
}

.el-form {
  line-height: 30px;
}

.form {
  padding-right: 12px;
  text-align: left;
  margin-left: 15px;
}

div {
  display: block;
  line-height: 10px;
  text-align: center;
}

</style>