<template>
  <div>
    <el-button class="search" size="large" @click="handleSearch = true"
        >Search</el-button
      >
      <!-- モーダル開始 -->
      <el-dialog
          v-model="handleSearch"
          width="70%"
          title=""
          destroy-on-close
          center
        >

      <el-form
        ref="Form"
        :model="formInline"
        label-width="150px"
        class="modalform"
      >
        <!-- 日付(範囲指定) -->
        <el-form :inline="true" :model="formInline" class="form">
          <el-form-item >
          <span class="form" id="date" >日付</span>
            <el-date-picker
              v-model="formInline.date"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 発令区分 -->
          <el-form-item class="form" id="dateOfIssuance" prop="dateOfIssuance" label="発令区分">
            <el-select v-model="formInline.dateOfIssuance" placeholder="発令区分">
            <!-- 入力している数字が表示されるように？ -->
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
          </el-select>
        </el-form-item>

          <!-- 発令内容区分 -->
          <el-form-item class="form" id="classificationOfTheContentOfTheIssuance" label="発令内容区分" prop="classificationOfTheContentOfTheIssuance">
            <el-select v-model="formInline.classificationOfTheContentOfTheIssuance" placeholder="発令内容区分">
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <!-- 社員番号(範囲指定？) -->
        <el-form class="form">
          <el-form-item class="form" id="employeeNumber" label="社員番号">
            <el-col :span="5">
              <el-input v-model="formInline.employeeNumberStart"></el-input>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="text-gray-500"> ～ </span>
            </el-col>
            <el-col :span="5">
              <el-input v-model="formInline.employeeNumberEnd"></el-input>
            </el-col>
          </el-form-item>
        </el-form>

        <!-- 会社コード(動的？) -->
        <el-form class="form">
          <el-form-item class="form" id="companyCode" label="会社コード">
            <el-col :span="5">
              <el-input v-model="formInline.companyCodeStart"></el-input>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="text-gray-500"> ～ </span>
            </el-col>
            <el-col :span="5">
              <el-input v-model="formInline.companyCodeEnd"></el-input>
            </el-col>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 所属部コード(動的？) -->
          <el-form-item class="form" id="affiliationClubCode" label="所属部コード">
            <el-input v-model="formInline.affiliationClubCode"></el-input>
          </el-form-item>
          <!-- 勤務地コード(動的？) -->
            <el-form-item class="form" id="placeOfWorkCode" label="勤務地コード">
              <el-input v-model="formInline.placeOfWorkCode"></el-input>
            </el-form-item>
        </el-form>

        <!-- 役職コード -->
        <el-form class="form">
          <el-form-item class="form" id="PostCode" label="役職コード">
            <el-col :span="5">
              <el-input v-model="formInline.PostCodeStart"></el-input>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="text-gray-500"> ～ </span>
            </el-col>
            <el-col :span="5">
              <el-input v-model="formInline.PostCodeEnd"></el-input>
            </el-col>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 待遇役職コード -->
          <el-form-item class="form" id="treatmentPositionCode" label="待遇役職コード">
            <el-input v-model="formInline.treatmentPositionCode"></el-input>
          </el-form-item>
          <!-- コースコード -->
          <el-form-item class="form" id="courseCode" label="コースコード">
            <el-input v-model="formInline.courseCode"></el-input>
          </el-form-item>
        </el-form>


        <el-form :inline="true" :model="formInline" class="form">
          <!-- 社員区分 -->
          <el-form-item class="form" id="employeeSegmentation" label="社員区分" prop="id">
            <el-select v-model="formInline.employeeSegmentation" placeholder="社員区分">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value=""></el-option>
              <el-option label="B" value=""></el-option>
            </el-select>
          </el-form-item>
          <!-- 社員名称 -->
          <el-form-item class="form" id="employeeName" label="社員名称">
            <el-input v-model="formInline.employeeName"></el-input>
          </el-form-item>
        </el-form>

        <!-- 時短区分 -->
        <el-form class="form">
          <el-form-item class="form" id="shortTimeDivision" label="時短区分" prop="id">
            <el-select v-model="formInline.shortTimeDivision" placeholder="時短区分">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 沖縄フラグ -->
        <el-form-item class="form" id="okinawaFlag" label="沖縄フラグ">
          <el-switch v-model="formInline.okinawaFlag"></el-switch>
        </el-form-item>
        <!-- 沖縄フラグ名称 -->
        <el-form-item class="form" id="okinawaFlagName" label="沖縄フラグ名称">
          <el-input v-model="formInline.okinawaFlagName"></el-input>
        </el-form-item>
        <!-- 沖縄フラグ変更日 -->
        <el-form-item class="form" id="okinawaFlagChangeDate" label="沖縄フラグ変更日">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.okinawaFlagChangeDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 沖縄変形労働フラグ -->
        <el-form-item class="form" id="okinawaDeformedLaborFlag" label="沖縄変形労働フラグ">
          <el-switch v-model="formInline.okinawaDeformedLaborFlag"></el-switch>
        </el-form-item>
        <!-- 沖縄変形労働フラグ名称 -->
        <el-form-item class="form" id="okinawaDeformedLaborFlagName" label="沖縄変形労働フラグ名称">
          <el-input v-model="formInline.okinawaDeformedLaborFlagName"></el-input>
        </el-form-item>
        <!-- 沖縄変形労働フラグ変更日 -->
        <el-form-item class="form" id="okinawaDeformedLaborFlagChangeDate" label="沖縄変形労働フラグ変更日">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.okinawaDeformedLaborFlagChangeDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 原籍会社コード -->
        <el-form-item class="form" id="originalCompanyCode" label="原籍会社コード">
          <el-input v-model="formInline.originalCompanyCode"></el-input>
        </el-form-item>
        <!-- 原籍部署コード -->
        <el-form-item class="form" id="originalDepartmentCode" label="原籍部署コード">
          <el-input v-model="formInline.OriginalDepartmentCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 原籍役職コード -->
        <el-form-item class="form" id="originalTitleCode" label="原籍役職コード">
          <el-input v-model="formInline.originalTitleCode"></el-input>
        </el-form-item>
        <!-- 職種コード -->
        <el-form-item class="form" id="jobCode" label="職種コード">
          <el-input v-model="formInline.jobCode"></el-input>
        </el-form-item>
      </el-form>
      

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 職種名称 -->
        <el-form-item class="form" id="jobName" label="職種名称">
          <el-input v-model="formInline.jobName"></el-input>
        </el-form-item>
        <!-- 兼務発令日付 -->
        <el-form-item class="form" id="dateOfConcurrentDecree" label="勤務発令日付">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.dateOfConcurrentDecree"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>    

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 最終兼務日 -->
        <el-form-item class="form" id="lastConcurrentDate" label="最終勤務日">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.lastConcurrentDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 兼務番号 -->
        <span class="form" id="concurrentNumber">勤務番号</span>
        <el-input-number
          v-model="formInline.concurrentNumber"
          :min="0"
          :max="100"
          controls-position="right"
          @change="handleChange"
        />
      </el-form>
    
      <el-form :inline="true" :model="formInline" class="form">
        <!-- 兼務自社他社区分名称 -->
        <el-form-item class="form" id="concurrentCompanyClassificationName" label="勤務自社自他者区分名称">
          <el-input v-model="formInline.concurrentCompanyClassificationName"></el-input>
        </el-form-item>
        <!-- 兼務会社コード -->
        <el-form-item class="form" id="concurrentCompanyAccounts" label="兼務会社コード">
          <el-input v-model="formInline.concurrentCompanyAccounts"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 兼務会社名称 -->
        <el-form-item class="form" id="concurrentCompanyName" label="兼務会社名称">
          <el-input v-model="formInline.concurrentCompanyName"></el-input>
        </el-form-item>
        <!-- 兼務所属部署コード -->
        <el-form-item class="form" id="concurrentDepartmentCode" label="兼務所属部署コード">
          <el-input v-model="formInline.concurrentDepartmentCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 兼務所属名称 -->
        <el-form-item class="form" id="nameOfConcurrentMember" label="兼務所属名称">
          <el-input v-model="formInline.nameOfConcurrentMember"></el-input>
        </el-form-item>
        <!-- 兼務役職コード -->
        <el-form-item class="form" id="concurrentJobTitleCode" label="兼務役職コード">
          <el-input v-model="formInline.concurrentJobTitleCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 兼務役職名称 -->
        <el-form-item class="form" id="concurrentPositionName" label="兼務役職名称">
          <el-input v-model="formInline.concurrentPositionName"></el-input>
        </el-form-item>
        <!-- 兼務待遇役職コード-->
        <el-form-item class="form" id="concurrentRecepteJobTitleCode" label="兼務待遇役職コード">
          <el-input v-model="formInline.concurrentRecepteJobTitleCode"></el-input>
        </el-form-item>  
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 兼務職種コード-->
        <el-form-item class="form" id="concurrentJobCode" label="兼務職種コード">
          <el-input v-model="formInline.concurrentJobCode"></el-input>
        </el-form-item>  
        <!-- 兼務職種名称-->
        <el-form-item class="form" id="concurrentJobName" label="兼務職種名称">
          <el-input v-model="formInline.concurrentJobName"></el-input>
        </el-form-item>
      </el-form>
      
      <!-- 休職区分-->
      <el-form class="form">
        <el-form-item class="form" id="leaveOfAbsenceCategory" label="休職区分" prop="id">
          <el-select v-model="formInline.leaveOfAbsenceCategory" placeholder="休職区分">
            <!-- 入力している数字が表示されるように？ -->
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
          </el-select>
        </el-form-item>
      </el-form>


      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 発令区分名称-->
        <el-form-item class="form" id="nameOfIssuanceClassification" label="発令区分名称">
          <el-input v-model="formInline.nameOfIssuanceClassification"></el-input>
        </el-form-item>
        <!-- 発令内容名称-->
        <el-form-item class="form" id="nameOfTheContentOfTheOrder" label="発令内容名称">
          <el-input v-model="formInline.nameOfTheContentOfTheOrder"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 登録日時-->
        <el-form-item class="form" id="registrationDate" label="登録日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.registrationDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 登録者-->
        <el-form-item class="form" id="registrants" label="登録者">
          <el-input v-model="formInline.registrants"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 更新日時-->
        <el-form-item class="form" id="updateDate" label="更新日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.updateDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 更新者-->
        <el-form-item class="form" id="Updater" label="更新者">
          <el-input v-model="formInline.Updater"></el-input>
        </el-form-item>
      </el-form>

    </el-form>

    <!-- ボタン -->
    <div class="button">
      <el-button @click="handleSearch = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm('formInline')"
        >Submit</el-button
      > 
    </div>

    </el-dialog>
    <!-- モーダル終了 -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import axios from "axios";

export default defineComponent({
  name: "SearchModal",
  props: ["clums"],
  setup() {
    const num = ref(1)
    const handleChange = (value) => {
      console.log(value)
    }
    const state = reactive({
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
          
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
    })

    return {
      ...toRefs(state),
      handleSearch: ref(false),
      num,
      handleChange,
    }
  },

  data() {
    return {
      formInline: {
          date: '',
          dateOfIssuance: '',
          issuanceClassification: '',
          classificationOfTheContentOfTheIssuance: '',
          employeeNumberStart: '',
          employeeNumberEnd: '',
          companyCodeStart: '',
          companyCodeEnd: '',
          affiliationClubCode: '',
          placeOfWorkCode: '',
          PostCodeStart: '',
          PostCodeEnd: '',
          treatmentPositionCode: '',
          courseCode: '',
          employeeSegmentation: '',
          employeeName: "",
          shortTimeDivision: '',
          okinawaFlag: false,
          okinawaFlagName: "",
          OkinawaFlagChangeDate: '',
          okinawaDeformedLaborFlag: false,
          okinawaDeformedLaborFlagName: "",
          okinawaDeformedLaborFlagChangeDate: '',
          originalCompanyCode: '',
          originalDepartmentCode: '',
          originalTitleCode: '',
          jobCode: '',
          jobName: "",
          dateOfConcurrentDecree: '',
          lastConcurrentDate: '',
          concurrentNumber: '',
          concurrentCompanyClassificationName: "",
          concurrentCompanyAccounts: '',
          concurrentCompanyName: "",
          concurrentDepartmentCode: '',
          nameOfConcurrentMember: "",
          concurrentJobTitleCode: '',
          concurrentPositionName: "",
          concurrentRecepteJobTitleCode: '',
          concurrentJobCode: '',
          concurrentJobName: "",
          leaveOfAbsenceCategory: '',
          nameOfIssuanceClassification: "",
          nameOfTheContentOfTheOrder: "",
          registrationDate: '',
          registrants: '',
          updateDate: '',
      },
    }
  },

  created() {
  },

  methods: {
    submitForm() {
      this.saveProduct();
    }
  },

    // Search product
    async saveProduct() {
      try {
        // 検索の処理
        // 入力された項目をDBに渡す
        await axios.put(
          `http://localhost:5000/company_m_hatsurei/`,this.formInline
        );
        // this.$router.go({path: this.$router.currentRoute.path, force: true})
        // alert("操作を完了しました");
      } catch (err) {
        console.log(err);
      }
    }
});
</script>

<style>
div > .search {
  margin: 10px;
  margin-top: 20px;
  float: right;
  background-color:rgb(111, 230, 230);
}

.el-form {
  line-height: 30px;
}

.form {
  padding-right: 12px;
  text-align: left;
  margin-left: 15px;
}

div {
  display: block;
  line-height: 10px;
  text-align: center;
}

</style>