<template>
  <el-aside width="200px">
    <el-menu :default-openeds="['1', '3']">
      <el-sub-menu index="1">
        <el-menu-item-group class="sideMenuItem">
          <el-menu-item index="1">マスタメンテナンス</el-menu-item>
            <el-menu-item class="mastarItem" index="1-1"><router-link to="/masater/companymenu">会社</router-link></el-menu-item>
            <el-menu-item class="mastarItem" index="1-2">組織</el-menu-item>
            <el-menu-item class="mastarItem" index="1-3">部門</el-menu-item>
          <el-menu-item index="2">レポート出力</el-menu-item>
          <el-menu-item index="3">データチェック</el-menu-item>
          <el-menu-item index="4">エラーチェック</el-menu-item>
          <el-menu-item index="5">連携チェック</el-menu-item>
        </el-menu-item-group>
      </el-sub-menu>
    </el-menu>
  </el-aside>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Header",
  setup() {
    return {
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
})
</script>

<style>
.el-aside {
  background-color: #d3dce6;
  color: var(--el-text-color-primary);
  text-align: left;
  line-height: 200px;
}

.sideMenuItem {
  background-color: #eef1f6;
  padding-left: 10px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.mastarItem { 
  margin-left: 30px;
}
</style>