<template>
  <div class="common-layout">
  <Header></Header>
  <el-container>
    <SideMenu></SideMenu>
    <el-main> 
      <router-view/>
    </el-main>
  </el-container>
  </div>
</template>

<script>
// import { defineComponent, ref } from 'vue';
import { defineComponent} from 'vue';
import Header from '../components/Header.vue'
import SideMenu from '../components/Sidemenu.vue'

export default defineComponent({
  components: {
    Header,
    SideMenu,
  },

  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
})
</script>

<style>
.el-main {
  background-color: #ffff;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 160px;
}

body {
  margin: 0px;
}

body > .el-container {
  margin-bottom: 40px;
}
</style>