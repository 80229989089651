<template>
  <div>
    <el-button class="add" size="large" @click="addForm(); handleAdd = true"
        >Add</el-button
      >
      <el-dialog
          v-model="handleAdd"
          width="70%"
          title=""
          destroy-on-close
          center
        >
    <el-form
      ref="InputForm"
      :model="InputForm"
      class="ruleForm"
    >
    <!-- 繰り返し構文 -->
      <el-form-item v-for="(clum) in data" :key="clum"
        :label="clum"
        :prop="clum"
      >
      <el-input
        v-model="InputForm[clum]"
        :type="clum"
        autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 繰り返し構文終了 -->
    
    <!-- ボタン -->
    <div class="button">
      <el-button @click="handleAdd = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm('InputForm')"
        >Submit</el-button
      > 
    </div>
      </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from "axios";


export default defineComponent({
  name: "AddModal",
  props: ["clums"],
  setup() {
    return {
      handleAdd: ref(false),
    }
  },
  data() {
    return {
      data:[],
      Items: [],
      InputForm: {
      },
    }
  },

  created() {
  },

  methods: {
    addForm() {
      //dataの初期化
      this.data.splice(0, this.data.length)
      for (var j in this.clums) {
        this.data.push(this.clums[j]);
      }
    },

    submitForm() {
      this.saveProduct();
    },

    // Create New product
    async saveProduct() {
      try {
        //Items[]の初期化
        this.Items.slice(0, this.Items.length);
        axios.post('http://localhost:5000/company_m_soshiki', this.InputForm
          )
          .then((response) => {
            console.log(response); 
            console.log(this.InputForm)       
        });
        // reloadでいいのか？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？
        this.$router.go({path: this.$router.currentRoute.path, force: true})
        alert("操作完了しました");
      } catch (err) {
        console.log(err);
        // message
      }
    }
  }
});
</script>

<style>
div > .add {
  margin: 10px;
  margin-top: 20px;
  float: right;
  background-color:rgb(111, 230, 230);
}

div > .button {
  display: block;
  line-height: 10px;
  text-align: center;
}
</style>