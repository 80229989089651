<template>
  <el-header style="text-align: right; font-size: 12px">
    <img src="../assets/samplelogo.png" />
    <span class="title">マスタ管理システム</span>
    <el-button>設定</el-button>
    <span class="username">ユーザー名</span>
  </el-header>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Header",
  setup() {
    return {
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
})
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
}

.title {
  float: left;
  font-size: 5ex;
}

.username {
  color: black;
  font-size: 13px;
}

img {
  float: left;
  width: 30px;
  padding-top: 5px;
  padding-right: 5px;
}

.el-header > .el-button {
  margin-right: 20px;
  color: black;
  background-color: #b3c0d1;
  border: none;
}

</style>