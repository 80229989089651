<template>
  <!-- <img src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <base-layout />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import BaseLayout from './layout/BaseLayout.vue'

export default {
  name: 'App',
  components: {
    BaseLayout
  }
}
</script>

<style>
</style>
