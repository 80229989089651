<template>
  <div>
    <h1 class="subTitle">COMPANY_M_Kojin</h1>
    <!-- searchButton -->
    <Search :clums="clums"></Search>
    <!-- addButton -->
    <Add :clums="ClumsNotId"></Add>

    <el-table :data="items" style="width: 100%">
      <!-- 繰り返し -->
      <el-table-column :label="column" :prop="column" v-for="column in propAndLabel" :key="column" />
      <el-table-column fixed="right" label="オペレーション" width="160">
        <template #default="scope">
          <div class="button">
          <!-- editButton -->
          <Edit :id="items[scope.$index].product_id" :clums="ClumsNotId" class="Edit"></Edit>
          <!-- deleteButton -->
          <Delete :id="items[scope.$index].product_id"></Delete>
          </div>
        </template>
      </el-table-column>
      <!-- 繰り返し終了 -->
    </el-table>

    <!-- Pagenation -->
    <el-pagination layout="prev, pager, next" :total="data.length"
      @current-change="handleCurrentChange"
    ></el-pagination>

    <!-- backButton -->
    <div class="backButton">
      <router-link to="/masater/companyMenu" class="backButton">戻る</router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent} from 'vue';
import axios from "axios";
import Edit from "../../../components/Modal/Company_M_Kojin/EditModal_Kojin.vue"
import Delete from "../../../components/Modal/Company_M_Hatsurei/DeleteModal_Hatsurei.vue"
import Add from "../../../components/Modal/Company_M_Kojin/AddModal_Kojin.vue"
import Search from "../../../components/Modal/Company_M_Kojin/SearchModal_Kojin.vue"

export default defineComponent({
  name: "ProductList",
  components: {
    Edit,
    Delete,
    Add,
    Search,
  },

  setup() {
    return {
    }
  },

  data() {
    return {
      // items[]の中に追加する
      items: [],
      data: [],
      // search: '',
      currentPage: 1, //現在いるページ。変更をdataに保存
      perPage: 1, //表示するItemの数
      // perPageItem: 0,
      start:0,
      // カラム名の取得
      clums: [],
      ClumsNotId: [],
      // 繰り返しの表
      propAndLabel: [],
    }
  },
  created() {
    this.getProducts();
  },

  // methods  //
  methods: {
    // Pagenation
    handleCurrentChange(val){
      this.getProducts();
      this.currentPage = val; //現在のページを取得
    },
    // Get All Products
    async getProducts() {
      try {
        const response = await axios.get("http://localhost:5000/company_m_kojin");
        // ページに表示するItemの範囲
        this.perPage = this.currentPage * 10;
        this.start = this.perPage -10;
        this.data = response.data;
        // ページごとの情報をitems[]に詰めなおす
        this.items = this.data.slice(this.start, this.perPage);
        
        //配列を空にする
        this.propAndLabel.splice(0, this.propAndLabel.length)
        // 全カラム名の再取得
        for (var key in this.data[0]) {
          //prop,labelにつめる
          this.propAndLabel.push(key)
          //全カラム情報の作成
          this.clums.push(key)
          //ClumsNotId[0]を削除したカラム情報の作成
          this.ClumsNotId.push(key)
          delete this.ClumsNotId[0];
        }

      } catch (err) {
        console.log(err);
      }
    },
  },
})
</script>

<style>
.subTitle {
  display:flex;
  padding: 15px;
  padding-top: 30px;
  font-size: 3rem;
  color: #a4a6aa;
  margin-block-start: 0px;
  margin-block-end: 0em;
  padding-inline-start: 0px;
  line-height: 0px;
}

.el-pagination {
  margin-top: 10px;
  line-height: 0px;
}

.el-table {
  padding-top: 10px;
  line-height: 0px;
}

.Edit {
  padding-right: 10px;
}

.button {
  display:flex;
}

.backButton {
  text-align: right;
  padding-right: 30px;
}

</style>