import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import CompanyMenu from '../views/master/company/CompanyMenu.vue'
import Hatsurei from '../views/master/company/company_m_Hatsurei.vue'
import Kojin from '../views/master/company/company_m_Kojin.vue'
import Soshiki from '../views/master/company/company_m_Soshiki.vue'
import Yakushoku from '../views/master/company/company_m_Yakushoku.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/masater/companymenu',
    name: 'CompanyMenu',
    component: CompanyMenu
  },
  {
    path: '/masater/company/hatsurei',
    name: 'MasterCompany_Hatsurei',
    component: Hatsurei
  },
  {
    path: '/masater/company/kojin',
    name: 'MasterCompany_Kojin',
    component: Kojin
  },
  {
    path: '/masater/company/soshiki',
    name: 'MasterCompany_Soshiki',
    component: Soshiki
  },
  {
    path: '/masater/company/yakushoku',
    name: 'MasterCompany_Yakushoku',
    component: Yakushoku
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
