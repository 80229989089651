<template>
  <div>
    <h1 class="subTitle">会社</h1>
    <router-link to="/masater/company/hatsurei" class="link">COMPANY_M_Hatsurei</router-link>
    <router-link to="/masater/company/kojin" class="link">COMPANY_M_Kojin</router-link>
    <router-link to="/masater/company/soshiki" class="link">COMPANY_M_Soshiki</router-link>
    <router-link to="/masater/company/yakushoku" class="link">COMPANY_M_Yakushoku</router-link>

  </div>
</template>

<script lang="ts">

</script>
<style>
.link {
  /* display: flex; */
  padding-right: 50px;
  line-height: 360px;
}
</style>