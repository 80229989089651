<template #default="scope">
  <div>
    <el-button
      size="mini"
      type="danger" class="delete" @click="handleDelete = true"
      >Delete</el-button
    >
    <el-dialog
        v-model="handleDelete"
        width="30%"
        destroy-on-close
        center
      >
      <span class="conform">この行を削除しますか？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDelete = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="deleteProduct(id), handleDelete = true"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from "axios";

export default defineComponent({
  name: "EditModal",
  props: ["id"],
  setup() {
    return {
      handleDelete: ref(false),
    }
  },
  data() {
    return {
      // items[]の中に追加する
      items: [],
      data: [],
      search: '',
      currentPage: 1, //現在いるページ。変更をdataに保存
      perPage: 1, //表示するItemの数
      perPageItem: 0,
      start:0,
    }
  },
  created() {
    this.getProducts();
  },
  methods: {
    // Get All Products
    async getProducts() {
      try {
        const response = await axios.get("http://localhost:5000/company_m_hatsurei");
        // ページに表示するItemの範囲
        this.perPage = this.currentPage * 10;
        this.start = this.perPage -10;
        this.data = response.data;
        // ページごとの情報をitems[]に詰めなおす
        this.items = this.data.slice(this.start, this.perPage);
      } catch (err) {
        console.log(err);
      }
    },
  // Delete Product
    async deleteProduct(id) {
      try {
        await axios.delete(`http://localhost:5000/company_m_hatsurei/${id}`);
        this.getProducts();
        // reload？？？？？？？？？？？？？？？？？？？？？？？？？
        this.$router.go({path: this.$router.currentRoute.path, force: true})
        alert("行を削除しました");
      } catch (err) {
        console.log(err);
      }
    },
  },
})
</script>

<style>
.edit {
  margin-right: 10px;
}

.conform {
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>