<template>
  <div>
    <el-button  class="edit" size="mini" @click="getProductById(); handleEdit = true"
      >Edit</el-button
    >
    <el-dialog
        v-model="handleEdit"
        width="70%"
        destroy-on-close
        center
      >
    <span><b>Id:{{id}}</b></span>
      <!-- 繰り返し構文 -->
      <el-form v-for="(clum) in data" 
        :key="clum.index"
        ref="InputForm"
        :model="InputForm"
        class="ruleForm"
      >
      <!-- <el-form-item
        :label="clum"
        :prop="clum"
        :rules="[
          { required: true, message: 'This is required' },
        ]"
      > -->
      <el-form-item
        :label="clum"
        :prop="clum"
      >
      <el-input
          v-model="InputForm[clum]"
          :type="clum"
          autocomplete="off"
          ></el-input>
      </el-form-item>
      </el-form>
      <!-- 繰り返し構文終了 -->

      <!-- ボタン -->
      <div class="button">
        <el-form-item>
          <el-button @click="handleEdit = false">Cancel</el-button>
          <el-button type="primary" @click="submitForm('InputForm')"
            >Submit</el-button
          >
        </el-form-item>
      </div>
      <!-- </el-form> -->
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from "axios";
// import { useRouter } from "vue-router";
// import validate from '../utils/validate';

export default defineComponent({
  name: "EditModal",
  props: ["id", "clums"],
  setup() {
    return {
      handleEdit: ref(false),
    }
  },

  computed: {
  },

  data() {
    return {
      data:[],
      InputForm: {
      },
    };
  },

  created: function () {
    //dataの初期化
    this.data.splice(0, this.data.length)
    for (var j in this.clums) {
      this.data.push(this.clums[j]);
    }
  },
  
  methods: {
    // Get Product By Id 編集箇所にデータを入れる
    async getProductById() {
      try {
          const response = await axios.get(
            `http://localhost:5000/company_m_kojin/${this.id}`
          );
          this.InputForm = response.data;
      } catch (err) {
        console.log(err);
      }
    },

    submitForm() {
    // submitForm(formName) {
      // validation
      // let validateFlag = true;
      //未入力だった場合
      // for (let i in this.data) {
      //   this.$refs[formName][i].validate((valid) => {
      //     if (!valid) {
      //         validateFlag = false;
      //         console.log('error submit!!')
      //     }
      //   })
      // }
      // if(validateFlag==true){
        console.log(this.id)
        this.updateProduct(this.id) ;
      // } else {
      //   validateFlag==false;
      //   return false
      // }
      // console.log(this.InputForm.product_price);
    },

    // Update product
    async updateProduct(id) {
      try {
        await axios.put(`http://localhost:5000/products/${id}`, this.InputForm
        );
        // reload？？？？？？？？？？？？？？？？？？？？？？？？？
        this.$router.go({path: this.$router.currentRoute.path, force: true})
        alert("行を更新しました");
      } catch (err) {
        console.log(err);
      }
    },
  },
});
</script>

<style>
.edit {
  margin-right: 10px;
}

.el-form-item__label {
  width: auto;
}

div > .button {
  display: block;
  line-height: 10px;
  text-align: center;
}

</style>