<template>
  <div>
    <el-button class="search" size="large" @click="handleSearch = true"
        >Search</el-button
      >
      <!-- モーダル開始 -->
      <el-dialog
          v-model="handleSearch"
          width="70%"
          title=""
          destroy-on-close
          center
        >

      <el-form
        ref="Form"
        :model="formInline"
        label-width="150px"
        class="modalform"
      >
        <el-form :inline="true" :model="formInline" class="form">
          <!-- 役職コード -->
          <el-form class="form">
            <el-form-item class="form" id="postCode" label="役職コード">
              <el-col :span="5">
                <el-input v-model="formInline.postCodeStart"></el-input>
              </el-col>
              <el-col :span="2" class="text-center">
                <span class="text-gray-500"> ～ </span>
              </el-col>
              <el-col :span="5">
                <el-input v-model="formInline.postCodeEnd"></el-input>
              </el-col>
            </el-form-item>
          </el-form>

          <!-- 取得年月日 -->
          <el-form class="form">
            <el-form-item class="form" id="acquisitionDate" label="取得年月日">
              <el-col :span="11">
                <el-date-picker
                  v-model="formInline.acquisitionDate"
                  type="date"
                  placeholder="Pick a date"
                  style="width: 200%"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </el-form>
        </el-form>

        <!-- 終了日付(範囲指定？) -->
        <el-form class="form">
          <el-form-item class="form" id="endDate" label="終了日付">
            <el-col :span="11">
              <el-date-picker
                v-model="formInline.endDate"
                type="date"
                placeholder="Pick a date"
                style="width: 200%"
              ></el-date-picker>
            </el-col>
          </el-form-item>
        </el-form>

        <!-- 役職名称(動的？) -->
        <el-form class="form">
          <el-form-item class="form" id="postName" label="役職名称">
            <el-input v-model="formInline.postName"></el-input>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 役職略称(動的？) -->
          <el-form-item class="form" id="postAbbreviation" label="役職略称">
            <el-input v-model="formInline.postAbbreviation"></el-input>
          </el-form-item>
          <!-- 役職名称辞令用(動的？) -->
            <el-form-item class="form" id="postNameForResignation" label="役職名称辞令用">
              <el-input v-model="formInline.postNameForResignation"></el-input>
            </el-form-item>
        </el-form>

        <!-- 役職辞令用正式名称 -->
        <el-form class="form">
            <el-form-item class="form" id="officialPostNameForResignation" label="役職辞令用正式名称">
              <el-input v-model="formInline.officialPostNameForResignation"></el-input>
            </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 役職社内報用名称 -->
          <el-form-item class="form" id="postNameForInReport" label="役職社内報用名称">
            <el-input v-model="formInline.postNameForInReport"></el-input>
          </el-form-item>
          <!-- 役職社外広報用名称 -->
          <el-form-item class="form" id="postNameForPublic" label="役職社外広報用名称">
            <el-input v-model="formInline.postNameForPublic"></el-input>
          </el-form-item>
        </el-form>


        <el-form :inline="true" :model="formInline" class="form">
          <!-- 役職順位 -->
          <el-form-item class="form" id="postRank" label="役職順位">
            <el-input v-model="formInline.postRank"></el-input>
          </el-form-item>
          <!-- 役職レベル -->
          <el-form-item class="form" id="postLabel" label="役職レベル">
            <el-input v-model="formInline.postLabel"></el-input>
          </el-form-item>
        </el-form>

        <!-- 分類区分１ -->
        <el-form class="form">
          <el-form-item class="form" id="classificationCategory1" label="分類区分１" prop="分類区分１">
            <el-select v-model="formInline.classificationCategory1" placeholder="分類区分１">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 分類区分２ -->
        <el-form class="form">
          <el-form-item class="form" id="classificationCategory2" label="分類区分２" prop="分類区分２">
            <el-select v-model="formInline.classificationCategory2" placeholder="分類区分２">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- コンカー用役職レベル -->
        <el-form-item class="form" id="postLevelForConker" label="コンカー用役職レベル">
          <el-input v-model="formInline.postLevelForConker"></el-input>
        </el-form-item>
        <!-- 分類区分４ -->
        <el-form class="form">
          <el-form-item class="form" id="classificationCategory4" label="分類区分４" prop="分類区分４">
            <el-select v-model="formInline.classificationCategory4" placeholder="分類区分４">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 分類区分５ -->
        <el-form class="form">
          <el-form-item class="form" id="classificationCategory5" label="分類区分５" prop="分類区分５">
            <el-select v-model="formInline.classificationCategory5" placeholder="分類区分５">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- ポスト定年制区分 -->
        <el-form-item class="form" id="PostRetirementAgeClassification" label="ポスト定年制区分">
          <el-input v-model="formInline.PostRetirementAgeClassification"></el-input>
        </el-form-item>
        <!-- ポスト任期制区分 -->
        <el-form-item class="form" id="PostTermDivision" label="ポスト任期制区分">
          <el-input v-model="formInline.PostTermDivision"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 他部門経歴必要区分 -->
        <el-form-item class="form" id="otherDivisionsRequiredCategories" label="他部門経歴必要区分">
          <el-input v-model="formInline.otherDivisionsRequiredCategories"></el-input>
        </el-form-item>
        <!-- 管理監督職区分 -->
        <el-form-item class="form" id="administrativeSupervisorshipDivision" label="管理監督職区分">
          <el-input v-model="formInline.administrativeSupervisorshipDivision"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- ライン区分 -->
        <el-form-item class="form" id="lineSegmentation" label="ライン区分">
          <el-input v-model="formInline.lineSegmentation"></el-input>
        </el-form-item>
        <!-- 決裁権限01 -->
        <el-form-item class="form" id="approvalAuthority01" label="決裁権限01">
          <el-input v-model="formInline.approvalAuthority01"></el-input>
        </el-form-item>
      </el-form>
      

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 決裁権限02 -->
        <el-form-item class="form" id="approvalAuthority02" label="決裁権限02">
          <el-input v-model="formInline.approvalAuthority02"></el-input>
        </el-form-item>
        <!-- 決裁権限03 -->
        <el-form-item class="form" id="approvalAuthority03" label="決裁権限03">
          <el-input v-model="formInline.approvalAuthority03"></el-input>
        </el-form-item>
      </el-form>    

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 決裁権限04 -->
        <el-form-item class="form" id="approvalAuthority04" label="決裁権限04">
          <el-input v-model="formInline.approvalAuthority04"></el-input>
        </el-form-item>
        <!-- 決裁権限05 -->
        <el-form-item class="form" id="approvalAuthority05" label="決裁権限05">
          <el-input v-model="formInline.approvalAuthority05"></el-input>
        </el-form-item>
      </el-form>
    
      <el-form :inline="true" :model="formInline" class="form">
        <!-- 決裁権限06 -->
        <el-form-item class="form" id="approvalAuthority06" label="決裁権限06">
          <el-input v-model="formInline.approvalAuthority06"></el-input>
        </el-form-item>
        <!-- 決裁権限07 -->
        <el-form-item class="form" id="approvalAuthority07" label="決裁権限07">
          <el-input v-model="formInline.approvalAuthority07"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 決裁権限08 -->
        <el-form-item class="form" id="approvalAuthority08" label="決裁権限08">
          <el-input v-model="formInline.approvalAuthority08"></el-input>
        </el-form-item>
        <!-- 手当支給区分 -->
        <el-form-item class="form" id="allowancePaymentClassification" label="手当支給区分">
          <el-input v-model="formInline.allowancePaymentClassification"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 年俸制対象区分 -->
        <el-form-item class="form" id="CategoryBySalary" label="年俸制対象区分">
          <el-input v-model="formInline.CategoryBySalary"></el-input>
        </el-form-item>
        <!-- ポスト分類区分01 -->
        <el-form-item class="form" id="postClassificationCategory01" label="ポスト分類区分01">
          <el-input v-model="formInline.postClassificationCategory01"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- ポスト分類区分02 -->
        <el-form-item class="form" id="postClassificationCategory02" label="ポスト分類区分02">
          <el-input v-model="formInline.postClassificationCategory02"></el-input>
        </el-form-item>
        <!-- ポスト分類区分03-->
        <el-form-item class="form" id="postClassificationCategory03" label="ポスト分類区分03">
          <el-input v-model="formInline.postClassificationCategory03"></el-input>
        </el-form-item>  
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- ポスト分類区分04-->
        <el-form-item class="form" id="postClassificationCategory04" label="ポスト分類区分04">
          <el-input v-model="formInline.postClassificationCategory04"></el-input>
        </el-form-item>  
        <!-- ポスト分類区分05-->
        <el-form-item class="form" id="postClassificationCategory05" label="ポスト分類区分05">
          <el-input v-model="formInline.postClassificationCategory05"></el-input>
        </el-form-item>
      </el-form>
      
      <!-- ポスト分類区分06-->
        <el-form-item class="form" id="postClassificationCategory06" label="ポスト分類区分06">
          <el-input v-model="formInline.postClassificationCategory06"></el-input>
        </el-form-item>


      <el-form :inline="true" :model="formInline" class="form">  
        <!-- ポスト分類区分07-->
        <el-form-item class="form" id="postClassificationCategory07" label="ポスト分類区分07">
          <el-input v-model="formInline.postClassificationCategory07"></el-input>
        </el-form-item>
        <!-- ポスト分類区分08-->
        <el-form-item class="form" id="postClassificationCategory08" label="ポスト分類区分08">
          <el-input v-model="formInline.postClassificationCategory08"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- ポスト分類区分09-->
        <el-form-item class="form" id="postClassificationCategory09" label="ポスト分類区分09">
          <el-input v-model="formInline.postClassificationCategory09"></el-input>
        </el-form-item>
        <!-- ポスト分類区分10-->
        <el-form-item class="form" id="postClassificationCategory10" label="ポスト分類区分10">
          <el-input v-model="formInline.postClassificationCategory10"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">      
      <!-- 備考-->
        <el-form-item class="form" id="remarks" label="備考">
          <el-input v-model="formInline.remarks"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formInline" class="form">
        
        <!-- 登録日時-->
        <el-form-item class="form" id="registrationDate" label="登録日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.registrationDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 登録者-->
        <el-form-item class="form" id="registrants" label="登録者">
          <el-input v-model="formInline.registrants"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 更新日時-->
        <el-form-item class="form" id="updateDate" label="更新日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.updateDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 更新者-->
        <el-form-item class="form" id="Updater" label="更新者">
          <el-input v-model="formInline.Updater"></el-input>
        </el-form-item>
      </el-form>

  </el-form>

    <!-- ボタン -->
    <div class="button">
      <el-button @click="handleSearch = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm('formInline')"
        >Submit</el-button
      > 
    </div>

    </el-dialog>
    <!-- モーダル終了 -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
// import axios from "axios";

export default defineComponent({
  name: "SearchModal",
  props: ["clums"],
  setup() {
    const num = ref(1)
    const handleChange = (value) => {
      console.log(value)
    }
    const state = reactive({
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
          
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
    })

    return {
      ...toRefs(state),
      handleSearch: ref(false),
      num,
      handleChange,
    }
  },

  data() {
    return {
      formInline: {
          postCodeStart: '',
          postCodeEnd: '',
          acquisitionDate: '',
          endDate: '',
          postName: '',
          postAbbreviation: '',
          postNameForResignation: '',
          officialPostNameForResignation: '',
          postNameForInReport: '',
          postNameForPublic: '',
          postRank: '',
          postLabel: '',
          classificationCategory1: '',
          classificationCategory2: '',
          postLevelForConker: '',
          classificationCategory4: '',
          classificationCategory5: '',
          PostRetirementAgeClassification: '',
          PostTermDivision: '',
          otherDivisionsRequiredCategories: '',
          administrativeSupervisorshipDivision: '',
          lineSegmentation: '',
          approvalAuthority01: '',
          approvalAuthority02: '',
          approvalAuthority03: '',
          approvalAuthority04: '',
          approvalAuthority05: '',
          approvalAuthority06: '',
          approvalAuthority07: '',
          approvalAuthority08: '',
          allowancePaymentClassification: '',
          CategoryBySalary: '',
          postClassificationCategory01: '',
          postClassificationCategory02: '',
          postClassificationCategory03: '',
          postClassificationCategory04: '',
          postClassificationCategory05: '',
          postClassificationCategory06: '',
          postClassificationCategory07: '',
          postClassificationCategory08: '',
          postClassificationCategory09: '',
          postClassificationCategory10: '',
          remarks: '',
          registrationDate: '',
          registrants: '',
          updateDate: '',
          Updater: '',
      }
    }
  },

  created() {
  },

  methods: {
    submitForm() {
      }
    },

    // Search product
    async saveProduct() {
      try {
        // 検索の処理

      } catch (err) {
        console.log(err);
      }
    }
});
</script>

<style>
div > .search {
  margin: 10px;
  margin-top: 20px;
  float: right;
  background-color:rgb(111, 230, 230);
}

.el-form {
  line-height: 30px;
}

.form {
  padding-right: 12px;
  text-align: left;
  margin-left: 15px;
}

div {
  display: block;
  line-height: 10px;
  text-align: center;
}

</style>