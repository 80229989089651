<template>
  <div>
    <el-button class="search" size="large" @click="handleSearch = true"
        >Search</el-button
      >
      <!-- モーダル開始 -->
      <el-dialog
          v-model="handleSearch"
          width="70%"
          title=""
          destroy-on-close
          center
        >

      <el-form
        ref="Form"
        :model="formInline"
        label-width="150px"
        class="modalform"
      >

      <el-form :inline="true" :model="formInline" class="form">
      <!-- 発令日 -->
      <el-form-item class="form" id="dateOfIssue" label="発令日">
        <el-col :span="11">
          <el-date-picker
            v-model="formInline.dateOfIssue"
            type="date"
            placeholder="Pick a date"
            style="width: 200%"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <!-- 終了日付 -->
      <el-form-item class="form" id="endDate" label="終了日付">
        <el-col :span="11">
          <el-date-picker
            v-model="formInline.endDate"
            type="date"
            placeholder="Pick a date"
            style="width: 200%"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      </el-form>

        <!-- 所属部署コード -->
        <el-form class="form">
          <el-form-item class="form" id="departmentCode" label="所属部署コード">
            <el-col :span="5">
              <el-input v-model="formInline.departmentCodeStart"></el-input>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="text-gray-500"> ～ </span>
            </el-col>
            <el-col :span="5">
              <el-input v-model="formInline.departmentCodeEnd"></el-input>
            </el-col>
          </el-form-item>
        </el-form>

        <!-- レベルＮＯ -->
        <el-form class="form">
          <el-form-item class="form" id="levelNo" label="レベルＮＯ">
            <el-col :span="5">
              <el-input v-model="formInline.levelNoStart"></el-input>
            </el-col>
            <el-col :span="2" class="text-center">
              <span class="text-gray-500"> ～ </span>
            </el-col>
            <el-col :span="5">
              <el-input v-model="formInline.levelNoEnd"></el-input>
            </el-col>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 親所属内順位(動的？) -->
          <el-form-item class="form" id="parentAffiliationRank" label="親所属内順位">
            <el-input v-model="formInline.parentAffiliationRank"></el-input>
          </el-form-item>
          <!-- 親所属部署コード(動的？) -->
            <el-form-item class="form" id="parentAffiliationCode" label="親所属部署コード">
              <el-input v-model="formInline.parentAffiliationCode"></el-input>
            </el-form-item>
        </el-form>

        <!-- 自レベル所属名称 -->
        <el-form class="form">
          <el-form-item class="form" id="nameOfAffiliationAtLevel" label="自レベル所属名称">
            <el-input v-model="formInline.nameOfAffiliationAtLevel"></el-input>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 自レベル所属略称 -->
          <el-form-item class="form" id="abbreviationNameOfAffiliationAtLevel" label="自レベル所属略称">
            <el-input v-model="formInline.abbreviationNameOfAffiliationAtLevel"></el-input>
          </el-form-item>
          <!-- 自レベル所属カナ名称 -->
          <el-form-item class="form" id="KanaNameOfAffiliationAtLevel" label="自レベル所属カナ名称">
            <el-input v-model="formInline.KanaNameOfAffiliationAtLevel"></el-input>
          </el-form-item>
        </el-form>

        <el-form :inline="true" :model="formInline" class="form">
          <!-- 所属名称取得区分 -->
          <el-form-item class="form" id="affiliationNameAcquisitionClassification" label="所属名称取得区分" prop="所属名称取得区分">
            <el-select v-model="formInline.affiliationNameAcquisitionClassification" placeholder="社員区分">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value=""></el-option>
              <el-option label="B" value=""></el-option>
            </el-select>
          </el-form-item>
          <!-- 所属名称 -->
          <el-form-item class="form" id="affiliationName" label="所属名称">
            <el-input v-model="formInline.affiliationName"></el-input>
          </el-form-item>
        </el-form>

        <!-- 所属略称取得区分 -->
        <el-form class="form">
          <el-form-item class="form" id="affiliationAbbreviationAcquisitionClassification" label="所属略称取得区分" prop="affiliationAbbreviationAcquisitionClassification">
            <el-select v-model="formInline.affiliationAbbreviationAcquisitionClassification" placeholder="所属略称取得区分">
              <!-- 入力している数字が表示されるように？ -->
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属略称 -->
        <el-form-item class="form" id="abbreviatedNameOfAffiliation" label="所属略称">
          <el-input v-model="formInline.abbreviatedNameOfAffiliation"></el-input>
        </el-form-item>
        <!-- 所属カナ名称取得区分 -->
        <el-form-item class="form" id="affiliationKanaNameAcquisition" label="所属カナ名称取得区分">
          <el-input v-model="formInline.affiliationKanaNameAcquisition"></el-input>
        </el-form-item>
        <!-- 所属カナ名称 -->
        <el-form-item class="form" id="kanaNameToBelong" label="所属カナ名称">
          <el-input v-model="formInline.kanaNameToBelong"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 正式名称取得区分 -->
        <el-form-item class="form" id="officialNameAcquisitionClassification" label="正式名称取得区分">
          <el-switch v-model="formInline.officialNameAcquisitionClassification"></el-switch>
        </el-form-item>
        <!-- 所属正式名称 -->
        <el-form-item class="form" id="officialNameOfAffiliation" label="所属正式名称">
          <el-input v-model="formInline.officialNameOfAffiliation"></el-input>
        </el-form-item>
        <!-- 辞令用所属名称 -->
        <el-form-item class="form" id="nameAffiliationForResignation" label="辞令用所属名称">
          <el-input v-model="formInline.nameAffiliationForResignation"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属英名称 -->
        <el-form-item class="form" id="englishName" label="所属英名称">
          <el-input v-model="formInline.englishName"></el-input>
        </el-form-item>
        <!-- 所属英略称 -->
        <el-form-item class="form" id="abbreviatedEnglishNameOfAffiliation" label="所属英略称">
          <el-input v-model="formInline.abbreviatedEnglishNameOfAffiliation"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属英名称正式 -->
        <el-form-item class="form" id="englishNameOfficiallyBelonged" label="所属英名称正式">
          <el-input v-model="formInline.englishNameOfficiallyBelonged"></el-input>
        </el-form-item>
        <!-- 責任者役職コード -->
        <el-form-item class="form" id="headTitleCode" label="責任者役職コード">
          <el-input v-model="formInline.headTitleCode"></el-input>
        </el-form-item>
      </el-form>
      

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 原価コード -->
        <el-form-item class="form" id="costCode" label="原価コード">
          <el-input v-model="formInline.costCode"></el-input>
        </el-form-item>
        <!-- 中セグメント -->
        <el-form-item class="form" id="mediumSegment" label="中セグメント">
          <el-input v-model="formInline.mediumSegment"></el-input>
        </el-form-item>
      </el-form>    

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 原価Ｃコード -->
        <el-form-item class="form" id="costCCode" label="原価Ｃコード">
          <el-input v-model="formInline.costCCode"></el-input>
        </el-form-item>
        <!-- 会社コード -->
        <el-form-item class="form" id="companyCode" label="会社コード">
          <el-input v-model="formInline.companyCode"></el-input>
        </el-form-item>
      </el-form>
    
      <el-form :inline="true" :model="formInline" class="form">
        <!-- 業態コード -->
        <el-form-item class="form" id="businessTypeCode" label="業態コード">
          <el-input v-model="formInline.businessTypeCode"></el-input>
        </el-form-item>
        <!-- 勤務地コード -->
        <el-form-item class="form" id="placeOfWorkCode" label="勤務地コード">
          <el-input v-model="formInline.placeOfWorkCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 労災事業所コード -->
        <el-form-item class="form" id="workersCompensationOfficeCode" label="労災事業所コード">
          <el-input v-model="formInline.workersCompensationOfficeCode"></el-input>
        </el-form-item>
        <!-- 施設コード -->
        <el-form-item class="form" id="facilityCode" label="施設コード">
          <el-input v-model="formInline.facilityCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 旧所属コード -->
        <el-form-item class="form" id="formerAffiliationCode" label="旧所属コード">
          <el-input v-model="formInline.formerAffiliationCode"></el-input>
        </el-form-item>
        <!-- 業務経験歴パターンID -->
        <el-form-item class="form" id="workexperiencePatternId" label="業務経験歴パターンID">
          <el-input v-model="formInline.workexperiencePatternId"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 上級評定単位コード -->
        <el-form-item class="form" id="advancedGradeUnitCode" label="上級評定単位コード">
          <el-input v-model="formInline.advancedGradeUnitCode"></el-input>
        </el-form-item>
        <!-- 下級評定単位コード-->
        <el-form-item class="form" id="lowerGradeUnitsCode" label="下級評定単位コード">
          <el-input v-model="formInline.lowerGradeUnitsCode"></el-input>
        </el-form-item>  
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 管轄人事コード-->
        <el-form-item class="form" id="competentPersonnelCode" label="管轄人事コード">
          <el-input v-model="formInline.competentPersonnelCode"></el-input>
        </el-form-item>  
        <!-- 計上部門コード-->
        <el-form-item class="form" id="accrualDivisionCode" label="計上部門コード">
          <el-input v-model="formInline.accrualDivisionCode"></el-input>
        </el-form-item>
      </el-form>
      
      <!-- 要員集計コード-->
      <el-form class="form">
        <el-form-item class="form" id="personnelAggregationCode" label="要員集計コード">
          <el-input v-model="formInline.personnelAggregationCode"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 職種振分コード-->
        <el-form-item class="form" id="jobDivisionsCode" label="職種振分コード">
          <el-input v-model="formInline.jobDivisionsCode"></el-input>
        </el-form-item>
        <!-- 所属予備コード２-->
        <el-form-item class="form" id="affiliationReserveCode2" label="所属予備コード２">
          <el-input v-model="formInline.affiliationReserveCode2"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 所属予備コード３-->
        <el-form-item class="form" id="affiliationReserveCode3" label="所属予備コード３">
          <el-input v-model="formInline.affiliationReserveCode3"></el-input>
        </el-form-item>
        <!-- 所属予備コード４-->
        <el-form-item class="form" id="affiliationReserveCode4" label="所属予備コード４">
          <el-input v-model="formInline.affiliationReserveCode4"></el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="formInline" class="form">  
        <!-- 所属予備コード５-->
        <el-form-item class="form" id="affiliationReserveCode5" label="所属予備コード５">
          <el-input v-model="formInline.affiliationReserveCode5"></el-input>
        </el-form-item>
        <!-- 販原価フラグ-->
        <el-form-item class="form" id="salesCostFlag" label="販原価フラグ">
          <el-switch v-model="formInline.salesCostFlag"></el-switch>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">      
        <!-- 所属分類区分０２-->
        <el-form-item class="form" id="affiliationClassification02" label="所属分類区分０２">
          <el-input v-model="formInline.affiliationClassification02"></el-input>
        </el-form-item>
        <!-- 所属分類区分０３-->
        <el-form-item class="form" id="affiliationClassification03" label="所属分類区分０３">
          <el-input v-model="formInline.affiliationClassification03"></el-input>
        </el-form-item>
      </el-form>
    
      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分０４-->
        <el-form-item class="form" id="affiliationClassification04" label="所属分類区分０４">
          <el-input v-model="formInline.affiliationClassification04"></el-input>
        </el-form-item>
        <!-- 所属分類区分０５-->
        <el-form-item class="form" id="affiliationClassification05" label="所属分類区分０５">
          <el-input v-model="formInline.affiliationClassification05"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分０６-->
        <el-form-item class="form" id="affiliationClassification06" label="所属分類区分０６">
          <el-input v-model="formInline.affiliationClassification06"></el-input>
        </el-form-item>
        <!-- 所属分類区分０７-->
        <el-form-item class="form" id="affiliationClassification07" label="所属分類区分０７">
          <el-input v-model="formInline.affiliationClassification07"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分０８-->
        <el-form-item class="form" id="affiliationClassification08" label="所属分類区分０８">
          <el-input v-model="formInline.affiliationClassification08"></el-input>
        </el-form-item>
        <!-- 所属分類区分０９-->
        <el-form-item class="form" id="affiliationClassification09" label="所属分類区分０９">
          <el-input v-model="formInline.affiliationClassification09"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分１０-->
        <el-form-item class="form" id="affiliationClassification10" label="所属分類区分１０">
          <el-input v-model="formInline.affiliationClassification10"></el-input>
        </el-form-item>
        <!-- 電通出向ﾌﾗｸﾞ-->
        <el-form-item class="form" id="dentsuSecondmentFlag" label="電通出向ﾌﾗｸﾞ">
          <el-switch v-model="formInline.dentsuSecondmentFlag"></el-switch>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分１２-->
        <el-form-item class="form" id="affiliationClassification12" label="所属分類区分１２">
          <el-input v-model="formInline.affiliationClassification12"></el-input>
        </el-form-item>
        <!-- 所属分類区分１３-->
        <el-form-item class="form" id="affiliationClassification13" label="所属分類区分１３">
          <el-input v-model="formInline.affiliationClassification13"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分１４-->
        <el-form-item class="form" id="affiliationClassification14" label="所属分類区分１４">
          <el-input v-model="formInline.affiliationClassification14"></el-input>
        </el-form-item>
        <!-- 所属分類区分１５-->
        <el-form-item class="form" id="affiliationClassification15" label="所属分類区分１５">
          <el-input v-model="formInline.affiliationClassification15"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分１６-->
        <el-form-item class="form" id="affiliationClassification16" label="所属分類区分１６">
          <el-input v-model="formInline.affiliationClassification16"></el-input>
        </el-form-item>
        <!-- 所属分類区分１７-->
        <el-form-item class="form" id="affiliationClassification17" label="所属分類区分１７">
          <el-input v-model="formInline.affiliationClassification17"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分１８-->
        <el-form-item class="form" id="affiliationClassification18" label="所属分類区分１８">
          <el-input v-model="formInline.affiliationClassification18"></el-input>
        </el-form-item>
        <!-- 所属分類区分１９-->
        <el-form-item class="form" id="affiliationClassification19" label="所属分類区分１９">
          <el-input v-model="formInline.affiliationClassification19"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分２０-->
        <el-form-item class="form" id="affiliationClassification20" label="所属分類区分２０">
          <el-input v-model="formInline.affiliationClassification20"></el-input>
        </el-form-item>
        <!-- 所属分類区分２１-->
        <el-form-item class="form" id="affiliationClassification21" label="所属分類区分２１">
          <el-input v-model="formInline.affiliationClassification21"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分２２-->
        <el-form-item class="form" id="affiliationClassification22" label="所属分類区分２２">
          <el-input v-model="formInline.affiliationClassification22"></el-input>
        </el-form-item>
        <!-- 所属分類区分２３-->
        <el-form-item class="form" id="affiliationClassification23" label="所属分類区分２３">
          <el-input v-model="formInline.affiliationClassification23"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分２４-->
        <el-form-item class="form" id="affiliationClassification24" label="所属分類区分２４">
          <el-input v-model="formInline.affiliationClassification24"></el-input>
        </el-form-item>
        <!-- 所属分類区分２５-->
        <el-form-item class="form" id="affiliationClassification25" label="所属分類区分２５">
          <el-input v-model="formInline.affiliationClassification25"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分２６-->
        <el-form-item class="form" id="affiliationClassification26" label="所属分類区分２６">
          <el-input v-model="formInline.affiliationClassification26"></el-input>
        </el-form-item>
        <!-- 所属分類区分２７-->
        <el-form-item class="form" id="affiliationClassification27" label="所属分類区分２７">
          <el-input v-model="formInline.affiliationClassification27"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 所属分類区分２８-->
        <el-form-item class="form" id="affiliationClassification28" label="所属分類区分２８">
          <el-input v-model="formInline.affiliationClassification28"></el-input>
        </el-form-item>
        <!-- 所属分類区分２９-->
        <el-form-item class="form" id="affiliationClassification29" label="所属分類区分２９">
          <el-input v-model="formInline.affiliationClassification29"></el-input>
        </el-form-item>
      </el-form>

        <!-- 所属分類区分３０-->
        <el-form-item class="form" id="affiliationClassification30" label="所属分類区分３０">
          <el-input v-model="formInline.affiliationClassification30"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考０１-->
        <el-form-item class="form" id="remarks01" label="備考０１">
          <el-input v-model="formInline.remarks01"></el-input>
        </el-form-item>
        <!-- 備考０２-->
        <el-form-item class="form" id="remarks02" label="備考０２">
          <el-input v-model="formInline.remarks02"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考０３-->  
        <el-form-item class="form" id="remarks03" label="備考０３">
          <el-input v-model="formInline.remarks03"></el-input>
        </el-form-item>
        <!-- 備考０４-->
        <el-form-item class="form" id="remarks04" label="備考０４">
          <el-input v-model="formInline.remarks04"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考０５-->
        <el-form-item class="form" id="remarks05" label="備考０５">
          <el-input v-model="formInline.remarks05"></el-input>
        </el-form-item>
        <!-- 備考０６-->
        <el-form-item class="form" id="remarks06" label="備考０６">
          <el-input v-model="formInline.remarks06"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考０７-->
        <el-form-item class="form" id="remarks07" label="備考０７">
          <el-input v-model="formInline.remarks07"></el-input>
        </el-form-item>
        <!-- 備考０８-->
        <el-form-item class="form" id="remarks08" label="備考０８">
          <el-input v-model="formInline.remarks08"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考０９-->
        <el-form-item class="form" id="remarks09" label="備考０９">
          <el-input v-model="formInline.remarks09"></el-input>
        </el-form-item>
        <!-- 備考１０-->
        <el-form-item class="form" id="remarks10" label="備考１０">
          <el-input v-model="formInline.remarks10"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 備考メモ-->
        <el-form-item class="form" id="remarksMemo" label="備考メモ">
          <el-input v-model="formInline.remarksMemo"></el-input>
        </el-form-item>
        <!-- 新規組織フラグ-->
        <el-form-item class="form" id="newOrganizationFlag" label="新規組織フラグ">
          <el-switch v-model="formInline.newOrganizationFlag"></el-switch>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 更新日付-->
        <el-form-item class="form" id="updateDay" label="更新日付">
          <el-input v-model="formInline.updateDay"></el-input>
        </el-form-item>
      <!-- 取得年月日-->
        <el-form-item class="form" id="acquisitionDate" label="取得年月日">
          <el-input v-model="formInline.acquisitionDate"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 組織ID-->
        <el-form-item class="form" id="organizationId" label="組織ID">
          <el-input v-model="formInline.organizationId"></el-input>
        </el-form-item>
        <!-- リンク元組織ID-->
        <el-form-item class="form" id="LinkOriginOrganizationId" label="リンク元組織ID">
          <el-input v-model="formInline.LinkOriginOrganizationId"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 廃止フラグ-->
        <el-form-item class="form" id="abolitionFlag" label="廃止フラグ">
          <el-switch v-model="formInline.abolitionFlag"></el-switch>
        </el-form-item>
        <!-- 状態-->
        <el-form-item class="form" id="status" label="状態">
          <el-input v-model="formInline.status"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 順位-->
        <el-form-item class="form" id="rank" label="順位">
          <el-input v-model="formInline.rank"></el-input>
        </el-form-item>
        <!-- 組織改変履歴NO-->
        <el-form-item class="form" id="organizationalChangeHistoryNo" label="組織改変履歴NO">
          <el-input v-model="formInline.organizationalChangeHistoryNo"></el-input>
        </el-form-item>
      </el-form>


      <el-form :inline="true" :model="formInline" class="form">
        <!-- 登録日時-->
        <el-form-item class="form" id="registrationDate" label="登録日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.registrationDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 登録者-->
        <el-form-item class="form" id="registrants" label="登録者">
          <el-input v-model="formInline.registrants"></el-input>
        </el-form-item>
      </el-form>

      <el-form :inline="true" :model="formInline" class="form">
        <!-- 更新日時-->
        <el-form-item class="form" id="updateDate" label="更新日時">
          <el-col :span="11">
            <el-date-picker
              v-model="formInline.updateDate"
              type="date"
              placeholder="Pick a date"
              style="width: 200%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
        <!-- 更新者-->
        <el-form-item class="form" id="Updater" label="更新者">
          <el-input v-model="formInline.Updater"></el-input>
        </el-form-item>
      </el-form>


      <!-- ボタン -->
      <div class="button">
        <el-button @click="handleSearch = false">Cancel</el-button>
        <el-button type="primary" @click="submitForm('formInline')"
          >Submit</el-button
        > 
      </div>

    </el-dialog>
    <!-- モーダル終了 -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref } from 'vue'
// import axios from "axios";

export default defineComponent({
  name: "SearchModal",
  props: ["clums"],
  setup() {
    const num = ref(1)
    const handleChange = (value) => {
      console.log(value)
    }
    const state = reactive({
      shortcuts: [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
          
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
    })

    return {
      ...toRefs(state),
      handleSearch: ref(false),
      num,
      handleChange,
    }
  },

  data() {
    return {
      formInline: {
          dateOfIssue: '',
          endDate: '',
          departmentCodeStart: '',
          departmentCodeEnd: '',
          levelNoStart: '',
          levelNoEnd: '',
          parentAffiliationRank: '',
          parentAffiliationCode: '',
          nameOfAffiliationAtLevel: '',
          abbreviationNameOfAffiliationAtLevel: '',
          KanaNameOfAffiliationAtLevel: '',
          affiliationNameAcquisitionClassification: '',
          affiliationName: '',
          affiliationAbbreviationAcquisitionClassification: '',
          abbreviatedNameOfAffiliation: '',
          affiliationKanaNameAcquisition: '',
          kanaNameToBelong: '',
          officialNameAcquisitionClassification: '',
          officialNameOfAffiliation: '',
          nameAffiliationForResignation: '',
          englishName: '',
          abbreviatedEnglishNameOfAffiliation: '',
          englishNameOfficiallyBelonged: '',
          headTitleCode: '',
          costCode: '',
          mediumSegment: '',
          costCCode: '',
          companyCode: '',
          businessTypeCode: '',
          placeOfWorkCode: '',
          workersCompensationOfficeCode: '',
          facilityCode: '',
          formerAffiliationCode: '',
          workexperiencePatternId: '',
          advancedGradeUnitCode: '',
          lowerGradeUnitsCode: '',
          competentPersonnelCode: '',
          accrualDivisionCode: '',
          personnelAggregationCode: '',
          jobDivisionsCode: '',
          affiliationReserveCode2: '',
          affiliationReserveCode3: '',
          affiliationReserveCode4: '',
          affiliationReserveCode5: '',
          salesCostFlag: '',
          affiliationClassification02: '',
          affiliationClassification03: '',
          affiliationClassification04: '',
          affiliationClassification05: '',
          affiliationClassification06: '',
          affiliationClassification07: '',
          affiliationClassification08: '',
          affiliationClassification09: '',
          affiliationClassification10: '',
          dentsuSecondmentFlag: '',
          affiliationClassification12: '',
          affiliationClassification13: '',
          affiliationClassification14: '',
          affiliationClassification15: '',
          affiliationClassification16: '',
          affiliationClassification17: '',
          affiliationClassification18: '',
          affiliationClassification19: '',
          affiliationClassification20: '',
          affiliationClassification21: '',
          affiliationClassification22: '',
          affiliationClassification23: '',
          affiliationClassification24: '',
          affiliationClassification25: '',
          affiliationClassification26: '',
          affiliationClassification27: '',
          affiliationClassification28: '',
          affiliationClassification29: '',
          affiliationClassification30: '',
          remarks01: '',
          remarks02: '',
          remarks03: '',
          remarks04: '',
          remarks05: '',
          remarks06: '',
          remarks07: '',
          remarks08: '',
          remarks09: '',
          remarks10: '',
          remarksMemo: '',
          newOrganizationFlag: '',
          updateDay: '',
          acquisitionDate: '',
          organizationId: '',
          LinkOriginOrganizationId: '',
          abolitionFlag: '',
          status: '',
          rank: '',
          organizationalChangeHistoryNo: '',
          registrationDate: '',
          registrants: '',
          updateDate: '',
          Updater: '',
      },
    }
  },

  created() {
  },

  methods: {
    submitForm() {
      }
    },

    // Search product
    async saveProduct() {
      try {
        // 検索の処理

      } catch (err) {
        console.log(err);
      }
    }
});
</script>

<style>
div > .search {
  margin: 10px;
  margin-top: 20px;
  float: right;
  background-color:rgb(111, 230, 230);
}

.el-form {
  line-height: 30px;
}

.form {
  padding-right: 12px;
  text-align: left;
  margin-left: 15px;
}

div {
  display: block;
  line-height: 10px;
  text-align: center;
}

</style>